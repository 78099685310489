import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSetRecoilState } from "recoil";

import { fetchOxygenGraphData } from "../../HTTPS/GraphApis";
import {
  extractChartItemsWithMatchingEventDates
} from "../../utils/chartUtils";
import { groupByDateForLineGraph, localisingTimestamp, processGroupedDataForLineGraph } from "../../utils/reuseableFunc";
import { lineChartDataState } from "../../recoil/vitalTables/lineChartDataState";
import useVitalChartQuery from "../../hooks/useVitalChartQuery";
import { usePatient } from "../../context/PatientContext";

function OxygenChart({ events }) {
  const numberOfDaysToFetch = 35;
  const numberOfDaysToShow = 7;
  const { patient } = usePatient();

  const id = "oxygen";
  const chartName = patient.fname + "_" + id
  
  const setChartData = useSetRecoilState(lineChartDataState);

  // calling chart data
  const { data: chartData, isFetched, isLoading } = useVitalChartQuery(
    "oxygenGraph",
    fetchOxygenGraphData,
    numberOfDaysToFetch
  );

  // Check if chartData is available before rendering the chart
  if (isLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>Loading...</p>
    </div>;
  }

  // Check if chartData is available before rendering the chart
  if (!isFetched || !(chartData?.length > 0)) {
    setChartData([])
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      <p>No Data Found</p>
    </div>;
  }

  const localizedChartData = localisingTimestamp(chartData)

  const groupedByDate = groupByDateForLineGraph(localizedChartData)

  const originalCopy = processGroupedDataForLineGraph(groupedByDate, 'value', numberOfDaysToShow)
  setChartData(originalCopy)

  // Create a deep copy of the original data to ensure it is not referentially equal in memory
  // apexChart is modifying the object which is causing issue with recoil
  const roundOffedSpo2 = JSON.parse(JSON.stringify(originalCopy));

  return (
    <>
      <div className="w-full mt-10 border-8 border-black/40 rounded-lg">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "SpO2",
              type: "line",
              data: roundOffedSpo2,
            },
            {
              name: "Activities",
              type: "scatter",
              data: extractChartItemsWithMatchingEventDates(
                events,
                roundOffedSpo2,
              ),
            },
          ]}
          height="272"
          options={{
            chart: {
              id,
              parentHeightOffset: 0,
              toolbar: {
                show: true,
                export: {
                csv: {
                  filename: chartName,
                  headerCategory: "Date",
                },
                svg: {
                  filename: chartName
                },
                png: {
                  filename: chartName
                }
              }
              },
              foreColor: "#FAFCFE", // color of the legend's text
              background: 'rgba(0, 0, 0, 0.4)',
            },
            stroke: {
              // line of the graph setting
              width: 3,
              // curve: "smooth",
              colors: "#53C5EA",
            },
            dataLabels: {
              enabled: false,
            },
            markers: {
              // this is for dot on the graph
              size: [0, 8],
              strokeWidth: 0,
              colors: "#ED7117ff", // adding alpha values to disable default opacity
            },
            tooltip: {
              // tooltip setting when we hover over the charts dots
              enabled: false,
            },
            legend: {
              // this is for name of the graphs at the buttom
              show: false,
            },
            grid: {
              // bg lines of the graph
              borderColor: "rgba(236, 241, 244, 0.4)",
            },
            xaxis: {
              type: "datetime",
              axisTicks: { show: false },
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  colors: "#FAFCFE",
                  fontFamily: "Poppins, Helvetica, Arial, sans-serif",
                  fontWeight: 500,
                },
              },
            },
          }}
        />
      </div>
    </>
  );
}

export default OxygenChart;

